.page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title {
  font-weight: 400;
  font-size: 1.8em;
  margin-bottom: 10px;
}