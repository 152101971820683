body {
  margin: 0;
  font-family: "Raleway", sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.toolbar {
  display: flex;
  padding: 1em 1.2em;
  box-shadow: 0px 2px 6px 0px #6d6d6d;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 1.3em;
  cursor: pointer;
}

.logout-button {
  padding: 0.5em;
  text-transform: uppercase;
  border: 1px solid black;
  background: white;
  border-radius: 5px;
  cursor: pointer;
}

.logout-button:hover {
  background: #dedede;
}

.page {
  padding: 20px;
}

label {
  font-size: 1.1em;
  font-weight: 400;
  display: block;
  margin-top: 15px;
}

input[type="text"],
input[type="password"],
input[type="number"],
input[type="email"] {
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px 10px 7px 10px;
  outline: none;
  font-size: 1.1em;
  margin: 5px 0;
}

input[type="submit"] {
  margin: 15px 0;
  border: 1px solid black !important;
  border-radius: 5px;
  padding: 0.5em;
  background: white;
  text-transform: uppercase;
  cursor: pointer;
  display: block;
}

input[type="submit"]:hover {
  background: #dedede;
}

h1,
h2 {
  font-weight: 400;
}

table {
  border-collapse: collapse;
  flex-grow: 1;
  margin: 20px;
}

table,
td,
th {
  /* border: 1px solid black; */
  padding: 10px;
  text-align: left;
}

thead tr {
  background: #ccc;
}

tbody tr:nth-child(2n) {
  background: #efefef;
}
